import $ from 'jquery';
import 'what-input';



// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import './lib/slick';
import './lib/cookie.notice.min';

$(document).foundation();
$(document).ready(function () {
    var slickPrimarySecondary = {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      infinite: false
    };
    var slickNavigator = {
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.nav',
      speed: 500,
      dots: true,
      focusOnSelect: true,
      centerMode: false,
      infinite: false
    };

    $('.primary').slick(slickPrimarySecondary);
    $('.secondary').slick(slickPrimarySecondary);

    $('.navigator').slick(slickNavigator)
  });
  var header = jQuery(".ueberuns");
  var headerScroll = "navigation2";
  jQuery(document).scroll(function () {
    if (jQuery(this).scrollTop() > 1) {
      header.removeClass(headerScroll);
    } else {
      header.addClass(headerScroll);
    }
  });

    new cookieNoticeJS({


       // Position for the cookie-notifier (default=bottom)
       'cookieNoticePosition': 'bottom',

       // Shows the "learn more button (default=false)
       'learnMoreLinkEnabled': true,

       // The href of the learn more link must be applied if (learnMoreLinkEnabled=true)
       'learnMoreLinkHref': '{{root}}impressum.html',


       // The message will be shown again in X days
       'expiresIn': 14,

      // Specify a custom font family
      'fontFamily': 'Nunito',

       // Dismiss button background color
       'buttonBgColor': '#C97064',  

       // Dismiss button text color
       'buttonTextColor': '#fff',

       // Notice background color
       'noticeBgColor': '#fff',

       // Notice text color
       'noticeTextColor': '#000',

       // the learnMoreLink color (default='#009fdd')
       'linkColor': '#C97064',


       // Print debug output to the console (default=false)
       'debug': false
    });
